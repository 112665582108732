.fdz-theme .dn {
  display: none !important;
}
.fdz-theme .di {
  display: inline !important;
}
.fdz-theme .db {
  display: block !important;
}
.fdz-theme .df {
  display: flex !important;
}
.fdz-theme .dif {
  display: inline-flex !important;
}
.fdz-theme .pa0 {
  padding: 0 !important;
}
.fdz-theme .pa8 {
  padding: 8px !important;
}
.fdz-theme .pv0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.fdz-theme .pt0 {
  padding-top: 0 !important;
}
.fdz-theme .pt8 {
  padding-top: 8px !important;
}
.fdz-theme .pt16 {
  padding-top: 16px !important;
}
.fdz-theme .pb0 {
  padding-bottom: 0 !important;
}
.fdz-theme .pb4 {
  padding-bottom: 4px !important;
}
.fdz-theme .pb8 {
  padding-bottom: 8px !important;
}
.fdz-theme .pb16 {
  padding-bottom: 16px !important;
}
.fdz-theme .ph0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.fdz-theme .pl0 {
  padding-left: 0 !important;
}
.fdz-theme .pr0 {
  padding-right: 0 !important;
}
.fdz-theme .pr16 {
  padding-right: 16px !important;
}
.fdz-theme .ma-auto {
  margin: auto;
}
.fdz-theme .ma0 {
  margin: 0 !important;
}
.fdz-theme .ma8 {
  margin: 8px !important;
}
.fdz-theme .mh0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.fdz-theme .mv0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}
.fdz-theme .mv8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}
.fdz-theme .mv16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}
.fdz-theme .mt0 {
  margin-top: 0 !important;
}
.fdz-theme .mt4 {
  margin-top: 4px !important;
}
.fdz-theme .mt8 {
  margin-top: 8px !important;
}
.fdz-theme .mt16 {
  margin-top: 16px !important;
}
.fdz-theme .mt18 {
  margin-top: 18px !important;
}
.fdz-theme .mb0 {
  margin-bottom: 0 !important;
}
.fdz-theme .mb8 {
  margin-bottom: 8px !important;
}
.fdz-theme .mb12 {
  margin-bottom: 12px !important;
}
.fdz-theme .mb16 {
  margin-bottom: 16px !important;
}
.fdz-theme .mb18 {
  margin-bottom: 18px !important;
}
.fdz-theme .mh0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.fdz-theme .mh8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}
.fdz-theme .mh16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}
.fdz-theme .ml0 {
  margin-left: 0 !important;
}
.fdz-theme .ml6 {
  margin-left: 6px !important;
}
.fdz-theme .ml9 {
  margin-left: 9px !important;
}
.fdz-theme .ml16 {
  margin-left: 16px !important;
}
.fdz-theme .ml28 {
  margin-left: 28px !important;
}
.fdz-theme .mr8 {
  margin-right: 8px !important;
}
.fdz-theme .mr10 {
  margin-right: 10px !important;
}
.fdz-theme .mr16 {
  margin-right: 16px !important;
}
.fdz-theme .mr20 {
  margin-right: 20px !important;
}
.fdz-theme .list {
  list-style-type: none !important;
}
.fdz-theme .w-10 {
  width: 10% !important;
}
.fdz-theme .w-20 {
  width: 20% !important;
}
.fdz-theme .w-25 {
  width: 25% !important;
}
.fdz-theme .w-30 {
  width: 30% !important;
}
.fdz-theme .w-33 {
  width: 33% !important;
}
.fdz-theme .w-34 {
  width: 34% !important;
}
.fdz-theme .w-40 {
  width: 40% !important;
}
.fdz-theme .w-50 {
  width: 50% !important;
}
.fdz-theme .w-60 {
  width: 60% !important;
}
.fdz-theme .w-70 {
  width: 70% !important;
}
.fdz-theme .w-75 {
  width: 75% !important;
}
.fdz-theme .w-80 {
  width: 80% !important;
}
.fdz-theme .w-90 {
  width: 90% !important;
}
.fdz-theme .w-100 {
  width: 100% !important;
}
.fdz-theme .ttc {
  text-transform: capitalize !important;
}
.fdz-theme .ttl {
  text-transform: lowercase !important;
}
.fdz-theme .ttu {
  text-transform: uppercase !important;
}
.fdz-theme .ttn {
  text-transform: none !important;
}
.fdz-theme .strike {
  text-decoration: line-through !important;
}
.fdz-theme .underline {
  text-decoration: underline !important;
}
.fdz-theme .no-underline {
  text-decoration: none !important;
}
.fdz-theme .normal {
  font-weight: normal !important;
}
.fdz-theme .b {
  font-weight: bold !important;
}
.fdz-theme .ws-normal {
  white-space: normal !important;
}
.fdz-theme .nowrap {
  white-space: nowrap !important;
}
.fdz-theme .pre {
  white-space: pre !important;
}
.fdz-theme .pointer:hover {
  cursor: pointer !important;
}
.fdz-theme .overflow-visible {
  overflow: visible !important;
}
.fdz-theme .overflow-hidden {
  overflow: hidden !important;
}
.fdz-theme .overflow-scroll {
  overflow: scroll !important;
}
.fdz-theme .overflow-auto {
  overflow: auto !important;
}
.fdz-theme .overflow-x-visible {
  overflow-x: visible !important;
}
.fdz-theme .overflow-x-hidden {
  overflow-x: hidden !important;
}
.fdz-theme .overflow-x-scroll {
  overflow-x: scroll !important;
}
.fdz-theme .overflow-x-auto {
  overflow-x: auto !important;
}
.fdz-theme .overflow-y-visible {
  overflow-y: visible !important;
}
.fdz-theme .overflow-y-hidden {
  overflow-y: hidden !important;
}
.fdz-theme .overflow-y-scroll {
  overflow-y: scroll !important;
}
.fdz-theme .overflow-y-auto {
  overflow-y: auto !important;
}
.fdz-theme .fg {
  flex-grow: 1;
}
.fdz-theme .flex-wrap {
  flex-wrap: wrap;
}
.fdz-theme .items-center {
  align-items: center;
}
.fdz-theme .self-start {
  align-self: flex-start;
}
.fdz-theme .ms-flex {
  -ms-flex: auto !important;
}

html,
body {
  min-width: 320px;
  overflow: hidden;
}

body {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  z-index: 0;
  background-color: rgb(250, 250, 250);
}

.ng-cloak,
.x-ng-cloak,
[data-ng-cloak],
[ng-cloak],
[ng\:cloak],
[x-ng-cloak] {
  display: none !important;
}

ui-view:focus {
  outline: none;
}

h5, .h5, h6, .h6, h7, .h7, h8, .h8, h9, .h9 {
  font-size: 14px;
}

h4, .h4, h5, .h5, h6, .h6, h7, .h7, h8, .h8, h9, .h9 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h1, h2, h3, h4, h5, h6, h7, h8, h9, .h1, .h2, .h3, .h4, .h5, .h6, .h7, .h8, .h9 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.fdz-theme em {
  font-style: italic;
}
.fdz-theme a {
  color: #006AB2;
  text-decoration: none;
}
.fdz-theme a:hover, .fdz-theme a:focus {
  text-decoration: underline;
}
.fdz-theme a:hover {
  color: #003c66;
  cursor: pointer;
}
.fdz-theme a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-color: rgb(247, 168, 57);
  outline-offset: -2px;
}
.fdz-theme a.md-button:hover,
.fdz-theme a.md-button:focus {
  text-decoration: none;
}
.fdz-theme h1,
.fdz-theme h2,
.fdz-theme h3 {
  margin: 0;
}
.fdz-theme h5 {
  margin-top: 0;
  margin-bottom: 0;
}
.fdz-theme .md-headline {
  font-size: 20px;
  line-height: 30px;
}
@media (min-width: 600px) {
  .fdz-theme .md-headline {
    font-size: 24px;
    line-height: 36px;
  }
}
.fdz-theme .md-title {
  font-size: 18px;
}
@media (min-width: 600px) {
  .fdz-theme .md-title {
    font-size: 20px;
  }
}
.fdz-theme .md-subtitle {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.38);
}
@media (min-width: 600px) {
  .fdz-theme .md-subtitle {
    font-size: 18px;
  }
}
.fdz-theme .fdz-primary {
  color: rgb(255, 255, 255);
}
.fdz-theme .fdz-secondary {
  color: rgb(0, 113, 188);
}
.fdz-theme .fdz-third {
  color: rgb(247, 168, 57) !important;
}
.fdz-theme .fdz-text {
  color: rgb(151, 151, 151);
}
.fdz-theme .md-button.fdz-small {
  min-width: 36px;
}
.fdz-theme .fdz-hyphenate {
  hyphens: manual;
  -webkit-hyphens: manual;
  -moz-hyphens: manual;
  -ms-hyphens: manual;
}
.fdz-theme .fdz-text-block {
  text-align: start;
}
.fdz-theme .fdz-multiline-property {
  white-space: pre-line;
}
.fdz-theme .md-dialog-content-body {
  white-space: pre-line;
}
.fdz-theme .fdz-truncate-string {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fdz-theme .fdz-truncate-string .md-title {
  min-height: 1.4em;
}
.fdz-theme .hljs {
  background-color: inherit;
}
.fdz-theme .fdz-code-container {
  white-space: pre-wrap;
  overflow: hidden;
}
.fdz-theme pre {
  margin: 0;
}
.fdz-theme .hide-validation-error .md-errors-spacer {
  display: none;
}
.fdz-theme .md-button,
.fdz-theme .md-tab {
  text-transform: none;
}

.fdz-theme md-sidenav {
  width: 100%;
  max-width: 100%;
  z-index: 20;
}
@media (min-width: 400px) {
  .fdz-theme md-sidenav {
    width: 400px;
    max-width: 400px;
  }
}
.fdz-theme md-sidenav, .fdz-theme md-sidenav md-content {
  background-color: rgb(255, 255, 255);
}

.fdz-footer {
  padding: 16px;
  color: rgba(0, 0, 0, 0.57);
}
.fdz-footer a {
  color: rgba(0, 0, 0, 0.57);
  padding: 8px;
}
.fdz-footer .fdz-footer__link {
  width: 100%;
}
@media (min-width: 570px) {
  .fdz-footer .fdz-footer__link {
    width: auto;
    display: inline-flex;
  }
}
.fdz-footer .logo .dzhw,
.fdz-footer .logo .bmbf {
  height: auto;
  width: 120px;
  margin: 0 16px;
}

.fdz-theme .md-button:not([disabled]):hover {
  background-color: rgb(240, 240, 240);
}
.fdz-theme .md-button:not([disabled]).md-focused {
  background-color: rgb(240, 240, 240);
}
.fdz-theme .md-button:not([disabled]).md-icon-button:hover {
  background-color: transparent;
}
.fdz-theme .md-button.md-fab {
  background-color: rgb(247, 168, 57);
  color: #000;
}
.fdz-theme .md-button.md-fab md-icon {
  color: #000;
}
.fdz-theme .md-button.md-fab:not([disabled]):hover {
  background-color: rgb(240, 129, 45);
}
.fdz-theme .md-button.md-fab:not([disabled]).md-focused {
  background-color: rgb(240, 129, 45);
}
.fdz-theme .md-button.md-primary {
  color: rgb(0, 113, 188);
}
.fdz-theme .md-button.md-primary.md-raised, .fdz-theme .md-button.md-primary.md-fab {
  color: rgb(0, 113, 188);
  background-color: rgb(255, 255, 255);
}
.fdz-theme .md-button.md-primary.md-raised:not([disabled]) md-icon, .fdz-theme .md-button.md-primary.md-fab:not([disabled]) md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme .md-button.md-primary.md-raised:not([disabled]):hover, .fdz-theme .md-button.md-primary.md-fab:not([disabled]):hover {
  background-color: rgb(240, 240, 240);
}
.fdz-theme .md-button.md-primary.md-raised:not([disabled]).md-focused, .fdz-theme .md-button.md-primary.md-fab:not([disabled]).md-focused {
  background-color: rgb(240, 240, 240);
}
.fdz-theme .md-button.md-primary:not([disabled]) md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme .md-button.md-fab {
  background-color: rgb(247, 168, 57);
  color: #000;
}
.fdz-theme .md-button.md-fab:not([disabled]) .md-icon {
  color: #000;
}
.fdz-theme .md-button.md-fab:not([disabled]):hover {
  background-color: rgb(240, 129, 45);
}
.fdz-theme .md-button.md-fab:not([disabled]).md-focused {
  background-color: rgb(240, 129, 45);
}
.fdz-theme .md-button.md-raised {
  color: rgb(60, 60, 60);
  background-color: rgb(255, 255, 255);
}
.fdz-theme .md-button.md-raised:not([disabled]) md-icon {
  color: rgb(60, 60, 60);
}
.fdz-theme .md-button.md-raised:not([disabled]):hover {
  background-color: rgb(255, 255, 255);
}
.fdz-theme .md-button.md-raised:not([disabled]).md-focused {
  background-color: rgb(245, 245, 245);
}
.fdz-theme .md-button.md-inline-tiny {
  min-height: 20px;
  height: 20px;
  padding: 0px;
  width: 20px;
  margin: 0px;
  border-radius: unset;
  line-height: inherit;
}
.fdz-theme .md-button.md-inline-tiny md-icon {
  font-size: 20px;
}
.fdz-theme .md-button.md-small {
  font-size: 12px;
  line-height: inherit;
  text-transform: none;
}
.fdz-theme .md-button.md-warn {
  color: rgb(247, 168, 57);
}
.fdz-theme .md-button.md-warn.md-raised, .fdz-theme .md-button.md-warn.md-fab {
  color: #000;
  background-color: rgb(247, 168, 57);
}
.fdz-theme .md-button.md-warn.md-raised:not([disabled]) md-icon, .fdz-theme .md-button.md-warn.md-fab:not([disabled]) md-icon {
  color: #000;
}
.fdz-theme .md-button.md-warn.md-raised:not([disabled]):hover, .fdz-theme .md-button.md-warn.md-fab:not([disabled]):hover {
  background-color: #F69F24;
}
.fdz-theme .md-button.md-warn.md-raised:not([disabled]).md-focused, .fdz-theme .md-button.md-warn.md-fab:not([disabled]).md-focused {
  background-color: #F69F24;
}
.fdz-theme .md-button.md-warn:not([disabled]) md-icon {
  color: rgb(247, 168, 57);
}
.fdz-theme .md-button.md-accent {
  color: rgb(247, 168, 57);
}
.fdz-theme .md-button.md-accent.md-raised, .fdz-theme .md-button.md-accent.md-fab {
  color: #000;
  background-color: rgb(247, 168, 57);
}
.fdz-theme .md-button.md-accent.md-raised:not([disabled]) md-icon, .fdz-theme .md-button.md-accent.md-fab:not([disabled]) md-icon {
  color: #000;
}
.fdz-theme .md-button.md-accent.md-raised:not([disabled]):hover, .fdz-theme .md-button.md-accent.md-fab:not([disabled]):hover {
  background-color: rgb(240, 129, 45);
}
.fdz-theme .md-button.md-accent.md-raised:not([disabled]).md-focused, .fdz-theme .md-button.md-accent.md-fab:not([disabled]).md-focused {
  background-color: rgb(240, 129, 45);
}
.fdz-theme .md-button.md-accent:not([disabled]) md-icon {
  color: rgb(247, 168, 57);
}
.fdz-theme .md-button[disabled], .fdz-theme .md-button.md-raised[disabled], .fdz-theme .md-button.md-fab[disabled], .fdz-theme .md-button.md-accent[disabled], .fdz-theme .md-button.md-warn[disabled] {
  color: rgba(0, 0, 0, 0.38);
  cursor: default;
}
.fdz-theme .md-button[disabled] md-icon, .fdz-theme .md-button.md-raised[disabled] md-icon, .fdz-theme .md-button.md-fab[disabled] md-icon, .fdz-theme .md-button.md-accent[disabled] md-icon, .fdz-theme .md-button.md-warn[disabled] md-icon {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme .md-button.md-raised[disabled], .fdz-theme .md-button.md-fab[disabled] {
  background-color: rgb(240, 240, 240);
}
.fdz-theme .md-button[disabled] {
  background-color: transparent;
}

.fdz-theme ._md a:not(.md-button).md-primary {
  color: rgb(0, 113, 188);
}
.fdz-theme ._md a:not(.md-button).md-primary:hover {
  color: rgb(0, 130, 208);
}
.fdz-theme ._md a:not(.md-button).md-accent {
  color: rgb(247, 168, 57);
}
.fdz-theme ._md a:not(.md-button).md-accent:hover {
  color: rgb(240, 129, 45);
}
.fdz-theme ._md a:not(.md-button).md-warn {
  color: rgb(247, 168, 57);
}
.fdz-theme ._md a:not(.md-button).md-warn:hover {
  color: rgb(217, 24, 53);
}

.fdz-theme md-autocomplete {
  background: rgb(255, 255, 255);
}
.fdz-theme md-autocomplete[disabled]:not([md-floating-label]) {
  background: rgb(240, 240, 240);
}
.fdz-theme md-autocomplete button md-icon path {
  fill: rgb(151, 151, 151);
}
.fdz-theme md-autocomplete button:after {
  background: rgba(151, 151, 151, 0.32);
}
.fdz-theme md-autocomplete input {
  color: rgb(0, 0, 0);
}
.fdz-theme md-autocomplete.md-accent md-input-container.md-input-focused .md-input {
  border-color: rgb(247, 168, 57);
}
.fdz-theme md-autocomplete.md-accent md-input-container.md-input-focused label,
.fdz-theme md-autocomplete.md-accent md-input-container.md-input-focused md-icon {
  color: rgb(247, 168, 57);
}
.fdz-theme md-autocomplete.md-accent md-progress-linear .md-container {
  background-color: #08fff2;
}
.fdz-theme md-autocomplete.md-accent md-progress-linear .md-bar {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-autocomplete.md-warn md-input-container.md-input-focused .md-input {
  border-color: rgb(217, 24, 53);
}
.fdz-theme md-autocomplete.md-warn md-input-container.md-input-focused label,
.fdz-theme md-autocomplete.md-warn md-input-container.md-input-focused md-icon {
  color: rgb(217, 24, 53);
}
.fdz-theme md-autocomplete.md-warn md-progress-linear .md-container {
  background-color: #F69F24;
}
.fdz-theme md-autocomplete.md-warn md-progress-linear .md-bar {
  background-color: rgb(247, 168, 57);
}

.fdz-theme .md-autocomplete-suggestions-container,
.fdz-theme .md-autocomplete-standard-list-container {
  background: rgb(255, 255, 255);
}
.fdz-theme .md-autocomplete-suggestions-container .md-autocomplete-suggestion,
.fdz-theme .md-autocomplete-standard-list-container .md-autocomplete-suggestion {
  color: rgb(0, 0, 0);
}
.fdz-theme .md-autocomplete-suggestions-container .md-autocomplete-suggestion:hover, .fdz-theme .md-autocomplete-suggestions-container .md-autocomplete-suggestion.selected,
.fdz-theme .md-autocomplete-standard-list-container .md-autocomplete-suggestion:hover,
.fdz-theme .md-autocomplete-standard-list-container .md-autocomplete-suggestion.selected {
  background: rgba(194, 194, 194, 0.18);
}

.fdz-theme md-backdrop {
  background-color: rgba(60, 60, 60, 0);
}
.fdz-theme md-backdrop.md-sidenav-backdrop {
  z-index: 19;
}
.fdz-theme md-backdrop.md-opaque {
  background-color: rgba(194, 194, 194, 0.32);
}

.fdz-theme md-card {
  position: relative;
  padding: 8px;
  margin: 8px 0;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
}
@media (min-width: 600px) {
  .fdz-theme md-card {
    padding: 16px;
  }
}
@media (min-width: 959px) {
  .fdz-theme md-card {
    margin: 8px;
  }
}
.fdz-theme md-card md-card-header {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.fdz-theme md-card md-card-header md-card-header-text {
  display: flex;
  justify-content: center;
}
.fdz-theme md-card md-card-header md-card-header-text .md-subhead {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-card md-card-header:focus {
  outline: none;
}
.fdz-theme md-card .md-card-collapsible-button {
  min-width: 24px;
  min-height: 24px;
  line-height: 24px;
  padding: 8px;
  margin: auto;
  display: inline-flex;
  justify-content: center;
}
.fdz-theme md-card .md-card-collapsible-button md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme md-card md-card-title md-card-title-text:not(:only-child) .md-subhead {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-card md-card-content {
  display: block;
  padding: 8px 0 0 0;
}
@media (min-width: 600px) {
  .fdz-theme md-card md-card-content {
    padding: 16px 0 0 0;
  }
}
.fdz-theme md-card md-card-actions {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}
.fdz-theme md-card md-card-actions .md-button {
  min-width: unset;
  margin: 0;
}

.fdz-theme form md-card-header-text {
  padding-bottom: 8px;
}
@media (min-width: 600px) {
  .fdz-theme form md-card-header-text {
    padding-bottom: 16px;
  }
}

.fdz-theme md-card-header-text.pb {
  padding-bottom: 8px;
}
@media (min-width: 600px) {
  .fdz-theme md-card-header-text.pb {
    padding-bottom: 16px;
  }
}

.fdz-theme md-checkbox {
  min-height: 25px;
}
.fdz-theme md-checkbox .md-ripple {
  color: rgb(240, 129, 45);
}
.fdz-theme md-checkbox .md-icon, .fdz-theme md-checkbox .md-container {
  width: 20px;
  height: 20px;
}
.fdz-theme md-checkbox.md-focused:not([disabled]) .md-container:before {
  background-color: rgba(0, 0, 0, 0.12);
  border-radius: 0px;
}
.fdz-theme md-checkbox.md-checked .md-ripple {
  color: rgb(151, 151, 151);
}
.fdz-theme md-checkbox.md-checked.md-focused .md-container {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-checkbox .md-ink-ripple {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-checkbox.md-checked .md-ink-ripple {
  color: rgb(247, 168, 57);
}
.fdz-theme md-checkbox:not(.md-checked) .md-icon {
  border-color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-checkbox.md-checked .md-icon {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-checkbox.md-checked .md-icon:after {
  border-color: #000;
}
.fdz-theme md-checkbox:not([disabled]).md-primary .md-ripple {
  color: rgb(0, 149, 228);
}
.fdz-theme md-checkbox:not([disabled]).md-primary.md-checked .md-ripple {
  color: rgb(151, 151, 151);
}
.fdz-theme md-checkbox:not([disabled]).md-primary .md-ink-ripple {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-checkbox:not([disabled]).md-primary.md-checked .md-ink-ripple {
  color: rgba(255, 255, 255, 0.87);
}
.fdz-theme md-checkbox:not([disabled]).md-primary:not(.md-checked) .md-icon {
  border-color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-checkbox:not([disabled]).md-primary.md-checked .md-icon {
  background-color: rgba(255, 255, 255, 0.87);
}
.fdz-theme md-checkbox:not([disabled]).md-primary.md-checked.md-focused .md-container:before {
  background-color: rgba(255, 255, 255, 0.26);
}
.fdz-theme md-checkbox:not([disabled]).md-primary.md-checked .md-icon:after {
  border-color: rgb(0, 113, 188);
}
.fdz-theme md-checkbox:not([disabled]).md-primary .md-indeterminate[disabled] .md-container {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-checkbox:not([disabled]).ng-invalid {
  color: rgb(217, 24, 53);
}
.fdz-theme md-checkbox:not([disabled]).ng-invalid:not(.md-checked) .md-icon {
  border-color: rgb(217, 24, 53);
}
.fdz-theme md-checkbox:not([disabled]).md-warn .md-ripple {
  color: rgb(247, 168, 57);
}
.fdz-theme md-checkbox:not([disabled]).md-warn .md-ink-ripple {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-checkbox:not([disabled]).md-warn.md-checked .md-ink-ripple {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-checkbox:not([disabled]).md-warn:not(.md-checked) .md-icon {
  border-color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-checkbox:not([disabled]).md-warn.md-checked .md-icon {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-checkbox:not([disabled]).md-warn.md-checked.md-focused:not([disabled]) .md-container {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-checkbox:not([disabled]).md-warn.md-checked .md-icon:after {
  border-color: rgb(245, 245, 245);
}
.fdz-theme md-checkbox[disabled]:not(.md-checked) .md-icon {
  border-color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-checkbox[disabled].md-checked .md-icon {
  background-color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-checkbox[disabled].md-checked .md-icon:after {
  border-color: rgb(245, 245, 245);
}
.fdz-theme md-checkbox[disabled] .md-icon:after {
  border-color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-checkbox[disabled] .md-label {
  color: rgba(0, 0, 0, 0.38);
}

.fdz-theme md-chips .md-chips {
  box-shadow: 0 1px rgba(0, 0, 0, 0.12);
}
.fdz-theme md-chips .md-chips.md-focused {
  box-shadow: 0 2px rgb(255, 255, 255);
}
.fdz-theme md-chips .md-chips .md-chip-input-container input {
  color: rgb(0, 0, 0);
}
.fdz-theme md-chips .md-chips .md-chip-input-container input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-chips .md-chips .md-chip-input-container input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-chips .md-chips .md-chip-input-container input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-chips .md-chips .md-chip-input-container input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-chips .md-chips .md-chip-input-container input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme md-chips .md-chips .md-chip-input-container input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme md-chips md-chip {
  background: rgb(240, 240, 240);
  color: rgb(96, 96, 96);
}
.fdz-theme md-chips md-chip md-icon {
  color: rgb(129, 129, 129);
}
.fdz-theme md-chips md-chip.md-focused {
  background: rgb(255, 255, 255);
  color: rgb(0, 113, 188);
}
.fdz-theme md-chips md-chip.md-focused md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme md-chips md-chip._md-chip-editing {
  background: transparent;
  color: rgb(96, 96, 96);
}
.fdz-theme md-chips md-chip-remove .md-button md-icon path {
  fill: rgb(194, 194, 194);
}

.md-contact-suggestion span.md-contact-email {
  color: rgb(222, 222, 222);
}

.fdz-theme md-content {
  color: rgb(0, 0, 0);
  background-color: rgb(240, 240, 240);
  overflow-anchor: none;
}
.fdz-theme md-content .fdz-cc {
  display: flex;
  flex-flow: column;
  align-items: center;
}
.fdz-theme md-content .fdz-cc > * {
  max-width: 1000px;
  width: 100%;
}

/** Theme styles for mdDatepicker. */
.fdz-theme .md-datepicker-input {
  color: rgb(0, 0, 0);
}
.fdz-theme .md-datepicker-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme .md-datepicker-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme .md-datepicker-input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme .md-datepicker-input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme .md-datepicker-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme .md-datepicker-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme .md-datepicker-input-container {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.fdz-theme .md-datepicker-input-container.md-datepicker-focused {
  border-bottom-color: rgb(255, 255, 255);
}
.md-accent .fdz-theme .md-datepicker-input-container.md-datepicker-focused {
  border-bottom-color: rgb(247, 168, 57);
}
.md-warn .fdz-theme .md-datepicker-input-container.md-datepicker-focused {
  border-bottom-color: rgb(217, 24, 53);
}
.fdz-theme .md-datepicker-input-container.md-datepicker-invalid {
  border-bottom-color: rgb(217, 24, 53);
}
.fdz-theme .md-datepicker-calendar-pane {
  border-color: rgb(255, 255, 255);
}
.fdz-theme .md-datepicker-triangle-button .md-datepicker-expand-triangle {
  border-top-color: rgba(0, 0, 0, 0.54);
}
.fdz-theme .md-datepicker-open .md-datepicker-calendar-icon {
  color: rgb(255, 255, 255);
}
.fdz-theme .md-datepicker-open.md-accent .md-datepicker-calendar-icon, .md-accent .fdz-theme .md-datepicker-open .md-datepicker-calendar-icon {
  color: rgb(247, 168, 57);
}
.fdz-theme .md-datepicker-open.md-warn .md-datepicker-calendar-icon, .md-warn .fdz-theme .md-datepicker-open .md-datepicker-calendar-icon {
  color: rgb(217, 24, 53);
}
.fdz-theme .md-datepicker-calendar {
  background: rgb(255, 255, 255);
}
.fdz-theme .md-datepicker-input-mask-opaque {
  box-shadow: 0 0 0 9999px rgb(255, 255, 255);
}
.fdz-theme .md-datepicker-open .md-datepicker-input-container {
  background: rgb(255, 255, 255);
}

.fdz-theme .md-dialog-container {
  background-color: rgba(0, 0, 0, 0.54);
}

.fdz-theme md-dialog {
  border-radius: 4px;
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.fdz-theme md-dialog md-toolbar {
  color: rgb(0, 0, 0) !important;
}
.fdz-theme md-dialog.md-content-overflow .md-actions, .fdz-theme md-dialog.md-content-overflow md-dialog-actions {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.fdz-theme md-divider {
  border-top-color: rgba(0, 0, 0, 0.12);
}

.fdz-theme .layout-row > md-divider,
.fdz-theme .layout-xs-row > md-divider, .fdz-theme .layout-gt-xs-row > md-divider,
.fdz-theme .layout-sm-row > md-divider, .fdz-theme .layout-gt-sm-row > md-divider,
.fdz-theme .layout-md-row > md-divider, .fdz-theme .layout-gt-md-row > md-divider,
.fdz-theme .layout-lg-row > md-divider, .fdz-theme .layout-gt-lg-row > md-divider,
.fdz-theme .layout-xl-row > md-divider {
  border-right-color: rgba(0, 0, 0, 0.12);
}

.fdz-theme md-icon {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-icon.md-primary {
  color: rgb(0, 113, 188);
}
.fdz-theme md-icon.md-accent {
  color: rgb(247, 168, 57);
}
.fdz-theme md-icon.md-warn {
  color: rgb(247, 168, 57);
}

.fdz-theme md-input-container .md-input {
  color: rgb(0, 0, 0);
  border-color: rgba(0, 0, 0, 0.12);
}
.fdz-theme md-input-container .md-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container .md-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container .md-input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container .md-input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container .md-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme md-input-container .md-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme md-input-container > md-icon {
  color: rgb(0, 0, 0);
}
.fdz-theme md-input-container label,
.fdz-theme md-input-container .md-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container label.md-required:after {
  color: rgb(217, 24, 53);
}
.fdz-theme md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-input-container .md-input-messages-animation, .fdz-theme md-input-container .md-input-message-animation {
  color: rgb(217, 24, 53);
}
.fdz-theme md-input-container .md-input-messages-animation .md-char-counter, .fdz-theme md-input-container .md-input-message-animation .md-char-counter {
  color: rgb(0, 0, 0);
}
.fdz-theme md-input-container.md-input-focused .md-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container.md-input-focused .md-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container.md-input-focused .md-input::-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container.md-input-focused .md-input::placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-input-container.md-input-focused .md-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme md-input-container.md-input-focused .md-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.38);
  opacity: 1;
}
.fdz-theme md-input-container:not(.md-input-invalid).md-input-has-value label {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused .md-input, .fdz-theme md-input-container:not(.md-input-invalid).md-input-resized .md-input {
  border-color: rgb(0, 113, 188);
}
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused label,
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused.md-accent .md-input {
  border-color: rgb(247, 168, 57);
}
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused.md-accent label,
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused.md-accent md-icon {
  color: rgb(247, 168, 57);
}
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused.md-warn .md-input {
  border-color: rgb(217, 24, 53);
}
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused.md-warn label,
.fdz-theme md-input-container:not(.md-input-invalid).md-input-focused.md-warn md-icon {
  color: rgb(217, 24, 53);
}
.fdz-theme md-input-container.md-input-invalid .md-input {
  border-color: rgb(217, 24, 53);
}
.fdz-theme md-input-container.md-input-invalid label,
.fdz-theme md-input-container.md-input-invalid .md-input-message-animation,
.fdz-theme md-input-container.md-input-invalid .md-char-counter {
  color: rgb(217, 24, 53);
}
.fdz-theme md-input-container .md-input[disabled], [disabled] .fdz-theme md-input-container .md-input {
  border-bottom-color: transparent;
  color: rgba(0, 0, 0, 0.38);
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.38) 33%, transparent 0%);
  background-image: -ms-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.38) 100%);
}

.fdz-theme md-list md-list-item.md-2-line .md-list-item-text h3, .fdz-theme md-list md-list-item.md-2-line .md-list-item-text h4,
.fdz-theme md-list md-list-item.md-3-line .md-list-item-text h3,
.fdz-theme md-list md-list-item.md-3-line .md-list-item-text h4 {
  color: rgb(0, 0, 0);
}
.fdz-theme md-list md-list-item.md-2-line .md-list-item-text p,
.fdz-theme md-list md-list-item.md-3-line .md-list-item-text p {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-list .md-proxy-focus.md-focused div.md-no-style {
  background-color: rgb(250, 250, 250);
}
.fdz-theme md-list md-list-item .md-avatar-icon {
  background-color: rgba(0, 0, 0, 0.38);
  color: #E6E6E6;
}
.fdz-theme md-list md-list-item > md-icon {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-list md-list-item > md-icon.md-highlight {
  color: rgb(255, 255, 255);
}
.fdz-theme md-list md-list-item > md-icon.md-highlight.md-accent {
  color: rgb(247, 168, 57);
}

.fdz-theme md-menu-bar > button.md-button {
  color: rgb(0, 0, 0);
  border-radius: 2px;
}
.fdz-theme md-menu-bar md-menu > button {
  color: rgb(0, 0, 0);
}
.fdz-theme md-menu-bar md-menu.md-open > button, .fdz-theme md-menu-bar md-menu > button:focus {
  outline: none;
  background-color: rgba(194, 194, 194, 0.18);
}
.fdz-theme md-menu-bar.md-open:not(.md-keyboard-mode) md-menu:hover > button {
  background-color: rgba(194, 194, 194, 0.18);
}
.fdz-theme md-menu-bar:not(.md-keyboard-mode):not(.md-open) md-menu button:hover,
.fdz-theme md-menu-bar:not(.md-keyboard-mode):not(.md-open) md-menu button:focus {
  background: transparent;
}

.fdz-theme md-menu-content .md-menu > .md-button:after {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-menu-content .md-menu.md-open > .md-button {
  background-color: rgba(194, 194, 194, 0.18);
}

.fdz-theme md-toolbar .md-menu-toolbar {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
}
.fdz-theme md-toolbar .md-menu-toolbar md-toolbar-filler {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 113, 188);
}
.fdz-theme md-toolbar .md-menu-toolbar md-toolbar-filler md-icon {
  color: rgb(0, 113, 188);
}

.fdz-theme md-menu-content {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-menu-content md-menu-item {
  color: rgb(0, 0, 0);
}
.fdz-theme md-menu-content md-menu-item md-icon {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-menu-content md-menu-item .md-button[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-menu-content md-menu-item .md-button[disabled] md-icon {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-menu-content md-menu-divider {
  background-color: rgba(0, 0, 0, 0.12);
}

.fdz-theme ._md-panel-backdrop {
  background-color: rgba(60, 60, 60, 0.1);
}

.fdz-theme md-progress-circular path {
  stroke: rgb(0, 113, 188);
}
.fdz-theme md-progress-circular.md-warn path {
  stroke: rgb(247, 168, 57);
}
.fdz-theme md-progress-circular.md-accent path {
  stroke: rgb(247, 168, 57);
}

.fdz-theme md-progress-linear .md-container {
  background-color: rgb(224, 244, 254);
}
.fdz-theme md-progress-linear .md-bar {
  background-color: rgb(0, 113, 188);
}
.fdz-theme md-progress-linear.md-warn .md-container {
  background-color: #F69F24;
}
.fdz-theme md-progress-linear.md-warn .md-bar {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-progress-linear.md-accent .md-bar {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-progress-linear[md-mode=buffer].md-primary .md-bar1 {
  background-color: rgb(224, 244, 254);
}
.fdz-theme md-progress-linear[md-mode=buffer].md-primary .md-dashed:before {
  background: radial-gradient(rgb(224, 244, 254) 0%, rgb(224, 244, 254) 16%, transparent 42%);
}
.fdz-theme md-progress-linear[md-mode=buffer].md-warn .md-bar1 {
  background-color: #F69F24;
}
.fdz-theme md-progress-linear[md-mode=buffer].md-warn .md-dashed:before {
  background: radial-gradient(#F69F24 0%, #F69F24 16%, transparent 42%);
}

md-input-container .fdz-theme md-select .md-select-value span:first-child:after {
  color: rgb(0, 113, 188);
}
md-input-container:not(.md-input-focused):not(.md-input-invalid) .fdz-theme md-select .md-select-value span:first-child:after {
  color: rgba(0, 0, 0, 0.38);
}
md-input-container.md-input-focused:not(.md-input-has-value) .fdz-theme md-select .md-select-value {
  color: rgb(247, 168, 57);
}
md-input-container.md-input-focused:not(.md-input-has-value) .fdz-theme md-select .md-select-value.md-select-placeholder {
  color: rgb(247, 168, 57);
}
md-input-container.md-input-invalid .fdz-theme md-select .md-select-value {
  color: rgb(217, 24, 53) !important;
  border-bottom-color: rgb(217, 24, 53) !important;
}
md-input-container.md-input-invalid .fdz-theme md-select.md-no-underline .md-select-value {
  border-bottom-color: transparent !important;
}
md-input-container:not(.md-input-invalid).md-input-focused.md-accent .md-select-value {
  border-color: rgb(247, 168, 57);
}
md-input-container:not(.md-input-invalid).md-input-focused.md-accent .md-select-value span {
  color: rgb(247, 168, 57);
}
md-input-container:not(.md-input-invalid).md-input-focused.md-warn .md-select-value {
  border-color: rgb(217, 24, 53);
}
md-input-container:not(.md-input-invalid).md-input-focused.md-warn .md-select-value span {
  color: rgb(217, 24, 53);
}

.fdz-theme md-select[disabled] .md-select-value {
  border-bottom-color: transparent;
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.38) 0%, rgba(0, 0, 0, 0.38) 33%, transparent 0%);
  background-image: -ms-linear-gradient(left, transparent 0%, rgba(0, 0, 0, 0.38) 100%);
}
.fdz-theme md-select .md-select-value {
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.fdz-theme md-select .md-select-value.md-select-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-select .md-select-value span:first-child:after {
  color: rgb(217, 24, 53);
}
.fdz-theme md-select.md-no-underline .md-select-value {
  border-bottom-color: transparent !important;
}
.fdz-theme md-select.ng-invalid.ng-touched .md-select-value {
  color: rgb(217, 24, 53) !important;
  border-bottom-color: rgb(217, 24, 53) !important;
}
.fdz-theme md-select.ng-invalid.ng-touched.md-no-underline .md-select-value {
  border-bottom-color: transparent !important;
}
.fdz-theme md-select:not([disabled]):focus .md-select-value {
  border-bottom-color: rgb(0, 113, 188);
  color: rgb(0, 0, 0);
}
.fdz-theme md-select:not([disabled]):focus .md-select-value.md-select-placeholder {
  color: rgb(0, 0, 0);
}
.fdz-theme md-select:not([disabled]):focus.md-no-underline .md-select-value {
  border-bottom-color: transparent !important;
}
.fdz-theme md-select:not([disabled]):focus.md-accent .md-select-value {
  border-bottom-color: rgb(247, 168, 57);
}
.fdz-theme md-select:not([disabled]):focus.md-warn .md-select-value {
  border-bottom-color: rgb(247, 168, 57);
}
.fdz-theme md-select[disabled] .md-select-value {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-select[disabled] .md-select-value.md-select-placeholder {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-select[disabled] .md-select-icon {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-select .md-select-icon {
  color: rgba(0, 0, 0, 0.54);
}

.fdz-theme md-select-menu md-content {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-select-menu md-content md-optgroup {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-select-menu md-content md-option {
  color: rgb(0, 0, 0);
}
.fdz-theme md-select-menu md-content md-option[disabled] .md-text {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-select-menu md-content md-option:not([disabled]):focus, .fdz-theme md-select-menu md-content md-option:not([disabled]):hover, .fdz-theme md-select-menu md-content md-option:not([disabled]).md-focused {
  background-color: rgba(194, 194, 194, 0.18);
}
.fdz-theme md-select-menu md-content md-option[selected] {
  color: rgb(0, 113, 188);
}
.fdz-theme md-select-menu md-content md-option[selected]:focus {
  color: rgb(0, 149, 228);
}
.fdz-theme md-select-menu md-content md-option[selected].md-accent {
  color: rgb(247, 168, 57);
}
.fdz-theme md-select-menu md-content md-option[selected].md-accent:focus {
  color: rgb(240, 129, 45);
}

.fdz-theme .md-checkbox-enabled .md-ripple {
  color: rgb(0, 149, 228);
}
.fdz-theme .md-checkbox-enabled[selected] .md-ripple {
  color: rgb(151, 151, 151);
}
.fdz-theme .md-checkbox-enabled .md-ink-ripple {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme .md-checkbox-enabled[selected] .md-ink-ripple {
  color: rgba(255, 255, 255, 0.87);
}
.fdz-theme .md-checkbox-enabled:not(.md-checked) .md-icon {
  border-color: rgba(0, 0, 0, 0.54);
}
.fdz-theme .md-checkbox-enabled[selected] .md-icon {
  background-color: rgba(255, 255, 255, 0.87);
}
.fdz-theme .md-checkbox-enabled[selected].md-focused .md-container:before {
  background-color: rgba(255, 255, 255, 0.26);
}
.fdz-theme .md-checkbox-enabled[selected] .md-icon:after {
  border-color: rgb(0, 113, 188);
}
.fdz-theme .md-checkbox-enabled .md-indeterminate[disabled] .md-container {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme .md-checkbox-enabled md-option .md-text {
  color: rgb(0, 0, 0);
}

.fdz-theme md-slider .md-track {
  background-color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-slider .md-track-ticks {
  color: #08ff00;
}
.fdz-theme md-slider .md-disabled-thumb {
  border-color: #E6E6E6;
  background-color: #E6E6E6;
}
.fdz-theme md-slider.md-min .md-thumb:after {
  background-color: #E6E6E6;
  border-color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-slider.md-min .md-focus-ring {
  background-color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-slider.md-min[md-discrete] .md-thumb:after {
  background-color: #08ff00;
  border-color: transparent;
}
.fdz-theme md-slider.md-min[md-discrete] .md-sign {
  background-color: rgb(222, 222, 222);
}
.fdz-theme md-slider.md-min[md-discrete] .md-sign:after {
  border-top-color: rgb(222, 222, 222);
}
.fdz-theme md-slider.md-min[md-discrete][md-vertical] .md-sign:after {
  border-top-color: transparent;
  border-left-color: rgb(222, 222, 222);
}
.fdz-theme md-slider .md-track.md-track-fill {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-slider .md-thumb:after {
  border-color: rgb(247, 168, 57);
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-slider .md-sign {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-slider .md-sign:after {
  border-top-color: rgb(247, 168, 57);
}
.fdz-theme md-slider[md-vertical] .md-sign:after {
  border-top-color: transparent;
  border-left-color: rgb(247, 168, 57);
}
.fdz-theme md-slider .md-thumb-text {
  color: #000;
}
.fdz-theme md-slider.md-warn[md-vertical] .md-sign:after {
  border-top-color: transparent;
  border-left-color: rgb(247, 168, 57);
}
.fdz-theme md-slider.md-warn .md-thumb-text {
  color: #000 #000;
}
.fdz-theme md-slider.md-primary .md-focus-ring {
  background-color: rgba(126, 208, 251, 0.38);
}
.fdz-theme md-slider.md-primary .md-track.md-track-fill {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-slider.md-primary .md-thumb:after {
  border-color: rgb(255, 255, 255);
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-slider.md-primary .md-sign {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-slider.md-primary .md-sign:after {
  border-top-color: rgb(255, 255, 255);
}
.fdz-theme md-slider.md-primary[md-vertical] .md-sign:after {
  border-top-color: transparent;
  border-left-color: rgb(255, 255, 255);
}
.fdz-theme md-slider.md-primary .md-thumb-text {
  color: rgb(0, 113, 188);
}
.fdz-theme md-slider[disabled] .md-thumb:after {
  border-color: transparent;
}
.fdz-theme md-slider[disabled]:not(.md-min) .md-thumb:after, .fdz-theme md-slider[disabled][md-discrete] .md-thumb:after {
  background-color: rgba(0, 0, 0, 0.38);
  border-color: transparent;
}
.fdz-theme md-slider[disabled][readonly] .md-sign {
  background-color: rgb(222, 222, 222);
}
.fdz-theme md-slider[disabled][readonly] .md-sign:after {
  border-top-color: rgb(222, 222, 222);
}
.fdz-theme md-slider[disabled][readonly][md-vertical] .md-sign:after {
  border-top-color: transparent;
  border-left-color: rgb(222, 222, 222);
}
.fdz-theme md-slider[disabled][readonly] .md-disabled-thumb {
  border-color: transparent;
  background-color: transparent;
}

md-slider-container[disabled] > *:first-child:not(md-slider), md-slider-container[disabled] > *:last-child:not(md-slider) {
  color: rgba(0, 0, 0, 0.38);
}

.fdz-theme .md-subheader {
  color: rgba(0, 0, 0, 0.23);
  background-color: #E6E6E6;
}
.fdz-theme .md-subheader.md-primary {
  color: rgb(255, 255, 255);
}
.fdz-theme .md-subheader.md-accent {
  color: rgb(247, 168, 57);
}
.fdz-theme .md-subheader.md-warn {
  color: rgb(247, 168, 57);
}

.fdz-theme md-switch .md-ink-ripple {
  color: rgb(194, 194, 194);
}
.fdz-theme md-switch .md-thumb {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-switch .md-bar {
  background-color: rgb(194, 194, 194);
}
.fdz-theme md-switch.md-focused:not(.md-checked) .md-thumb:before {
  background-color: rgba(0, 0, 0, 0.12);
}
.fdz-theme md-switch.md-focused[disabled] .md-thumb:before {
  background-color: rgba(0, 0, 0, 0.12);
}
.fdz-theme md-switch.md-checked:not([disabled]) .md-ink-ripple {
  color: rgb(247, 168, 57);
}
.fdz-theme md-switch.md-checked:not([disabled]) .md-thumb {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-switch.md-checked:not([disabled]).md-primary .md-ink-ripple {
  color: rgb(255, 255, 255);
}
.fdz-theme md-switch.md-checked:not([disabled]).md-primary .md-thumb {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-switch.md-checked:not([disabled]).md-primary .md-bar {
  background-color: rgba(255, 255, 255, 0.5);
}
.fdz-theme md-switch.md-checked:not([disabled]).md-primary.md-focused .md-thumb:before {
  background-color: rgba(255, 255, 255, 0.26);
}
.fdz-theme md-switch.md-checked:not([disabled]).md-warn .md-ink-ripple {
  color: rgb(247, 168, 57);
}
.fdz-theme md-switch.md-checked:not([disabled]).md-warn .md-thumb {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-switch[disabled] .md-thumb {
  background-color: rgb(222, 222, 222);
}
.fdz-theme md-switch[disabled] .md-bar {
  background-color: rgba(0, 0, 0, 0.12);
}

.fdz-theme md-tabs md-tabs-wrapper {
  background-color: transparent;
  border-color: rgba(0, 0, 0, 0.12);
}
.fdz-theme md-tabs .md-paginator md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme md-tabs md-ink-bar {
  color: rgb(247, 168, 57);
  background: rgb(247, 168, 57);
}
.fdz-theme md-tabs .md-tab {
  color: rgba(0, 0, 0, 0.38);
  text-transform: none;
}
.fdz-theme md-tabs .md-tab[disabled], .fdz-theme md-tabs .md-tab[disabled] md-icon {
  color: rgba(0, 0, 0, 0.38);
  opacity: 0.4;
}
.fdz-theme md-tabs .md-tab.md-active, .fdz-theme md-tabs .md-tab.md-active md-icon, .fdz-theme md-tabs .md-tab.md-focused, .fdz-theme md-tabs .md-tab.md-focused md-icon {
  color: rgb(0, 0, 0);
}
.fdz-theme md-tabs .md-tab.md-focused {
  background: rgb(255, 255, 255);
}
.fdz-theme md-tabs .md-tab .md-ripple-container {
  color: rgb(247, 168, 57);
}
.fdz-theme md-tabs.md-accent > md-tabs-wrapper {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, .fdz-theme md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, .fdz-theme md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, .fdz-theme md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: #000;
}
.fdz-theme md-tabs.md-accent > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-ink-bar {
  color: rgb(0, 149, 228);
  background: rgb(0, 149, 228);
}
.fdz-theme md-tabs.md-primary > md-tabs-wrapper {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-tabs.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), .fdz-theme md-tabs.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
  color: rgb(224, 244, 254);
}
.fdz-theme md-tabs.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, .fdz-theme md-tabs.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, .fdz-theme md-tabs.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, .fdz-theme md-tabs.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme md-tabs.md-primary > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
  background: rgba(0, 113, 188, 0.1);
}
.fdz-theme md-tabs.md-warn > md-tabs-wrapper {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-tabs.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), .fdz-theme md-tabs.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
  color: #F69F24;
}
.fdz-theme md-tabs.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, .fdz-theme md-tabs.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, .fdz-theme md-tabs.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, .fdz-theme md-tabs.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: #000;
}
.fdz-theme md-tabs.md-warn > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
  background: #000;
}

.fdz-theme md-toolbar > md-tabs > md-tabs-wrapper {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), .fdz-theme md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
  color: rgb(224, 244, 254);
}
.fdz-theme md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, .fdz-theme md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, .fdz-theme md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, .fdz-theme md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: rgb(0, 113, 188);
}
.fdz-theme md-toolbar > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
  background: rgba(0, 113, 188, 0.1);
}

.fdz-theme md-toolbar.md-accent > md-tabs > md-tabs-wrapper {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-toolbar.md-accent > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, .fdz-theme md-toolbar.md-accent > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, .fdz-theme md-toolbar.md-accent > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, .fdz-theme md-toolbar.md-accent > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: #000;
}
.fdz-theme md-toolbar.md-accent > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-ink-bar {
  color: rgb(0, 149, 228);
  background: rgb(0, 149, 228);
}

.fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]), .fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]) md-icon {
  color: #F69F24;
}
.fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active, .fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-active md-icon, .fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused, .fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused md-icon {
  color: #000;
}
.fdz-theme md-toolbar.md-warn > md-tabs > md-tabs-wrapper > md-tabs-canvas > md-pagination-wrapper > md-tab-item:not([disabled]).md-focused {
  background: #000;
}

.fdz-theme md-toast .md-toast-content {
  background-color: #323232;
  color: rgb(255, 255, 255);
}
.fdz-theme md-toast .md-toast-content .md-button {
  color: rgb(255, 255, 255);
}
.fdz-theme md-toast .md-toast-content .md-button.md-highlight {
  color: rgb(247, 168, 57);
}
.fdz-theme md-toast .md-toast-content .md-button.md-highlight.md-primary {
  color: rgb(255, 255, 255);
}
.fdz-theme md-toast .md-toast-content .md-button.md-highlight.md-warn {
  color: rgb(247, 168, 57);
}
.fdz-theme md-toast.md-accent > .md-toast-content {
  font-weight: bold;
  border: 1px solid black;
  background-color: #F69F24;
  color: black !important;
}

.fdz-theme .toast-wrapper {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 2000;
  padding-top: 64px;
}

.fdz-theme #toast-container > md-toast {
  z-index: 2000;
  pointer-events: all;
}

.fdz-theme md-toolbar:not(.md-menu-toolbar) {
  background-color: rgb(255, 255, 255);
  color: rgb(0, 113, 188);
  z-index: 30;
}
.fdz-theme md-toolbar:not(.md-menu-toolbar) .md-button {
  margin: 0;
}
@media (min-width: 320px) {
  .fdz-theme md-toolbar:not(.md-menu-toolbar) .md-button {
    margin: unset;
  }
}
.fdz-theme md-toolbar:not(.md-menu-toolbar) md-icon {
  color: rgb(0, 113, 188);
  fill: rgb(0, 113, 188);
}
.fdz-theme md-toolbar:not(.md-menu-toolbar) .md-button[disabled] md-icon {
  color: rgb(0, 113, 188);
  fill: rgb(0, 113, 188);
}
.fdz-theme md-toolbar:not(.md-menu-toolbar).md-accent {
  background-color: rgb(247, 168, 57);
  color: #000;
}
.fdz-theme md-toolbar:not(.md-menu-toolbar).md-accent .md-ink-ripple {
  color: #000;
}
.fdz-theme md-toolbar:not(.md-menu-toolbar).md-accent md-icon {
  color: #000;
  fill: #000;
}
.fdz-theme md-toolbar:not(.md-menu-toolbar).md-accent .md-button[disabled] md-icon {
  color: rgb(0, 113, 188);
  fill: rgb(0, 113, 188);
}
.fdz-theme md-toolbar:not(.md-menu-toolbar).md-warn {
  background-color: rgb(247, 168, 57);
  color: #000;
}

.fdz-logo {
  padding: 10px 10px 10px 0;
  margin: 0;
}
@media (min-width: 320px) {
  .fdz-logo {
    margin: unset;
  }
}

.fdz-logo:hover,
.fdz-logo:active {
  background-color: rgba(0, 0, 0, 0) !important;
}

.fdz-logo > img {
  margin-top: 15px;
  height: 35px;
}
@media (min-width: 375px) {
  .fdz-logo > img {
    display: block;
  }
}
@media (min-width: 959px) {
  .fdz-logo > img {
    display: block;
    height: 50px;
  }
}

.fdz-theme .md-toolbar-tools {
  justify-content: space-between;
  padding: 0;
}
@media (min-width: 375px) {
  .fdz-theme .md-toolbar-tools {
    padding: 0 8px;
  }
}

.fdz-theme .md-panel.md-tooltip {
  color: rgb(255, 255, 255);
  background-color: rgb(129, 129, 129);
  font-size: 12px;
}

.fdz-theme .fdz-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.fdz-theme .fdz-start {
  position: relative;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
@media (min-width: 600px) {
  .fdz-theme .fdz-start {
    margin: 8px;
  }
}
.fdz-theme .fdz-start md-card {
  box-shadow: none;
}
.fdz-theme .fdz-start .fdz-link {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 14px;
  padding: 16px;
}
.fdz-theme .fdz-start .fdz-link a {
  padding: 8px;
}
@media (min-width: 700px) {
  .fdz-theme .fdz-start .fdz-link {
    flex-direction: row;
    justify-content: space-evenly;
    font-size: 16px;
    padding: 24px;
  }
}
@media (min-width: 860px) {
  .fdz-theme .fdz-start .fdz-link {
    font-size: 20px;
  }
}
.fdz-theme .fdz-start .fdz-search {
  margin-bottom: 8px;
}
@media (min-width: 600px) {
  .fdz-theme .fdz-start .fdz-search {
    margin-bottom: 0px;
  }
}
.fdz-theme .fdz-start__header {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 8px;
}
@media (min-width: 600px) {
  .fdz-theme .fdz-start__header {
    padding: 16px;
  }
}
.fdz-theme .fdz-start__header-text {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  word-break: break-word;
}
.fdz-theme .fdz-start__content {
  padding: 0 16px 0px 16px;
}
.fdz-theme .fdz-start__images {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0 16px 16px 16px;
}
@media (min-width: 600px) {
  .fdz-theme .fdz-start__images {
    flex-flow: row;
    justify-content: space-between;
  }
}
.fdz-theme .fdz-start__images .fdz-image {
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../../assets/images/graduates-series.jpg);
  background-position-y: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 0;
  padding-top: 52.64%;
  margin: 8px 8px 0px 8px;
}
@media (min-width: 600px) {
  .fdz-theme .fdz-start__images .fdz-image {
    padding-top: 20.64%;
    margin: 0px 8px 0px 8px;
  }
}
.fdz-theme .fdz-start__images .fdz-image .fdz-image-text {
  position: absolute;
  bottom: 8px;
  left: 8px;
  color: white;
  margin-right: 8px;
}
.fdz-theme .fdz-start__images .social-survey {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../../assets/images/social-survey.jpg);
}
.fdz-theme .fdz-start__images .school-leavers {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../../../../assets/images/school-leavers.jpg);
}

.fdz-theme md-nav-bar .md-nav-bar {
  background-color: transparent;
  border-bottom: none;
}
.fdz-theme md-nav-bar .md-button._md-nav-button.md-unselected {
  color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-nav-bar .md-button._md-nav-button[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-nav-bar md-nav-ink-bar {
  color: rgb(247, 168, 57);
  background: rgb(247, 168, 57);
}
.fdz-theme md-nav-bar.md-accent > .md-nav-bar {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-nav-bar.md-accent > .md-nav-bar .md-button._md-nav-button.md-active, .fdz-theme md-nav-bar.md-accent > .md-nav-bar .md-button._md-nav-button.md-focused {
  color: #000;
}
.fdz-theme md-nav-bar.md-accent > .md-nav-bar md-nav-ink-bar {
  color: rgb(0, 149, 228);
  background: rgb(0, 149, 228);
}
.fdz-theme md-nav-bar.md-warn > .md-nav-bar {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-nav-bar.md-warn > .md-nav-bar .md-button._md-nav-button {
  color: #F69F24;
}
.fdz-theme md-nav-bar.md-warn > .md-nav-bar .md-button._md-nav-button.md-active, .fdz-theme md-nav-bar.md-warn > .md-nav-bar .md-button._md-nav-button.md-focused {
  color: #000;
}
.fdz-theme md-nav-bar.md-warn > .md-nav-bar .md-button._md-nav-button.md-focused {
  background: #000;
}
.fdz-theme md-nav-bar.md-primary > .md-nav-bar {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-nav-bar.md-primary > .md-nav-bar .md-button._md-nav-button {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-nav-bar.md-primary > .md-nav-bar .md-button._md-nav-button.md-active, .fdz-theme md-nav-bar.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused {
  color: rgb(0, 113, 188);
}
.fdz-theme md-nav-bar.md-primary > .md-nav-bar .md-button._md-nav-button.md-focused {
  background: rgba(0, 113, 188, 0.1);
}

.fdz-theme md-toolbar > md-nav-bar > .md-nav-bar {
  background-color: rgb(255, 255, 255);
}
.fdz-theme md-toolbar > md-nav-bar > .md-nav-bar .md-button._md-nav-button {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-toolbar > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-active, .fdz-theme md-toolbar > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-focused {
  color: rgb(0, 113, 188);
}
.fdz-theme md-toolbar > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-focused {
  background: rgba(0, 113, 188, 0.1);
}

.fdz-theme md-toolbar.md-accent > md-nav-bar > .md-nav-bar {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-toolbar.md-accent > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-active, .fdz-theme md-toolbar.md-accent > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-focused {
  color: #000;
}
.fdz-theme md-toolbar.md-accent > md-nav-bar > .md-nav-bar md-nav-ink-bar {
  color: rgb(0, 149, 228);
  background: rgb(0, 149, 228);
}

.fdz-theme md-toolbar.md-warn > md-nav-bar > .md-nav-bar {
  background-color: rgb(247, 168, 57);
}
.fdz-theme md-toolbar.md-warn > md-nav-bar > .md-nav-bar .md-button._md-nav-button {
  color: #F69F24;
}
.fdz-theme md-toolbar.md-warn > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-active, .fdz-theme md-toolbar.md-warn > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-focused {
  color: #000;
}
.fdz-theme md-toolbar.md-warn > md-nav-bar > .md-nav-bar .md-button._md-nav-button.md-focused {
  background: #000;
}

.fdz-navbar-menu {
  width: 100%;
}
.fdz-navbar-menu a {
  color: black;
}

.fdz-navbar-menu-accent {
  width: 100%;
}

.fdz-navbar-menu-items {
  padding: 0;
  margin: 0;
  width: 100%;
}

.fdz-navbar-menu-toggle {
  display: block;
  padding: 0 0 0 1em;
  margin: 0 !important;
  width: 100%;
  height: 100%;
  text-align: left;
}

.fdz-navbar-menu-toggle > span {
  display: inline-block;
  width: 85%;
  text-transform: none !important;
  text-align: left !important;
}

.fdz-navbar-button {
  display: block;
  margin: 0 !important;
  padding: 0 0 0 1em;
  width: 100%;
  height: 100%;
  text-align: left !important;
  text-transform: none !important;
}

.fdz-navbar-menu-items .fdz-navbar-button {
  padding: 0 0 0 2em;
}

.fdz-navbar-button-active {
  background-color: #E5F0F7;
}

/** Theme styles for mdCalendar. */
.fdz-theme .md-calendar {
  background: rgb(255, 255, 255);
  color: rgba(0, 0, 0, 0.87);
}
.fdz-theme .md-calendar tr:last-child td {
  border-bottom-color: rgba(5, 153, 0, 0.93);
}
.fdz-theme .md-calendar-day-header {
  background: rgba(194, 194, 194, 0.32);
  color: rgba(0, 0, 0, 0.87);
}
.fdz-theme .md-calendar-date.md-calendar-date-today .md-calendar-date-selection-indicator {
  border: 1px solid rgb(0, 113, 188);
}
.fdz-theme .md-calendar-date.md-calendar-date-today.md-calendar-date-disabled {
  color: rgb(0, 162, 243);
}
.md-calendar-date.md-focus .fdz-theme .md-calendar-date-selection-indicator, .fdz-theme .md-calendar-date-selection-indicator:hover {
  background: rgba(194, 194, 194, 0.32);
}
.fdz-theme .md-calendar-date.md-calendar-selected-date .md-calendar-date-selection-indicator,
.fdz-theme .md-calendar-date.md-focus.md-calendar-selected-date .md-calendar-date-selection-indicator {
  background: rgb(0, 113, 188);
  color: rgba(255, 255, 255, 0.87);
  border-color: transparent;
}
.fdz-theme .md-calendar-date-disabled,
.fdz-theme .md-calendar-month-label-disabled {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme .md-calendar-month-label md-icon {
  color: rgb(0, 0, 0);
}

.fdz-theme .fdz-search-result {
  margin-bottom: 8px;
}
.fdz-theme .fdz-search-result a {
  color: rgb(0, 113, 188);
  cursor: pointer;
}
.fdz-theme .fdz-search-result a:focus {
  outline-color: rgb(247, 168, 57);
}
.fdz-theme .fdz-search-result md-card-header md-card-header-text, .fdz-theme .fdz-search-result md-card-header md-card-header-text .md-title, .fdz-theme .fdz-search-result md-card-header md-card-header-text .md-subhead > * {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fdz-theme .fdz-search-result md-card-header em {
  background-color: rgb(247, 168, 57);
  font-style: unset;
}
.fdz-theme .fdz-search-result .md-subhead {
  display: flex;
  flex-flow: wrap;
  justify-content: space-between;
}
.fdz-theme .fdz-search-result .md-subhead.left {
  justify-content: flex-start;
}
.fdz-theme .fdz-search-result md-card-content {
  padding: 8px 0 0 0;
  text-align: start;
}
.fdz-theme .fdz-search-result md-card-content .btn-link {
  font-weight: 400;
  color: rgb(0, 113, 188);
  border-radius: 0;
}
.fdz-theme .fdz-search-result md-card-content .btn-link:focus {
  outline-color: rgb(247, 168, 57);
}
.fdz-theme .fdz-search-result md-card-content em {
  background-color: rgb(247, 168, 57);
  font-style: unset;
}
.fdz-theme .fdz-search-result md-card-actions > a {
  margin: 0 8px 16px 8px;
}
.fdz-theme .fdz-search-result md-card-actionsr {
  display: flex;
  justify-content: flex-end;
  margin: -16px 8px 8px 8px;
}
.fdz-theme .fdz-search-result md-card-actionsr .md-button {
  min-width: unset;
}
.fdz-theme .fdz-search-result .md-card-search-action {
  margin: 8px 0 0 0;
  justify-content: flex-start;
}
.fdz-theme .fdz-search-result .md-card-search-action a {
  margin: 0;
}

.fdz-search-result__link {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.fdz-search-result__link:focus,
.fdz-search-result__link:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

.fdz-search-result__link:hover {
  color: black;
  text-decoration: none;
}

.fdz-search-result__link:focus {
  color: black;
  text-decoration: none;
  outline-color: rgb(247, 168, 57);
}

.fdz-no-result {
  display: flex;
  margin: 16px 8px 8px 8px;
  width: 100%;
}
@media (min-width: 600px) {
  .fdz-no-result {
    margin: 16px 8px 8px 16px;
  }
}

.ribbon {
  /* positioning */
  pointer-events: none;
  position: absolute;
  padding: 4px 0;
  width: 100px;
  /* top left of the page */
  top: 5px;
  left: calc(50% - 50px);
  text-align: center;
  overflow: hidden;
  /* effects with some shadow */
  box-shadow: 0 0 0 3px #C91630, 0 0 20px -3px rgba(0, 0, 0, 0.5);
  text-shadow: 0 0 0 #ffffff, 0 0 5px rgba(0, 0, 0, 0.3);
  /* looks */
  background-color: #C91630;
  color: #ffffff;
  text-transform: capitalize;
  /* ribbon effects */
  /* webkit antialias fix */
  -webkit-backface-visibility: hidden;
  z-index: 2000;
}

[data-ng-cloak].fdz-splash {
  display: table !important;
}

.fdz-splash {
  display: none;
  width: 100%;
  height: 100vh;
  background-color: white;
  color: black;
}

.fdz-splash .fdz-app-title {
  padding: 8px;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
}

.fdz-splash img {
  display: block;
  margin: 16px auto;
  max-width: 500px;
}

.fdz-splash progress {
  height: 1em;
  width: 10em;
}

.fdz-theme .md-button.md-fab.fdz-previous-search-result, .fdz-theme .md-button.md-fab.fdz-next-search-result {
  position: fixed;
  top: 75%;
  width: 40px;
  height: 40px;
  z-index: 10;
}
@media (min-width: 600px) {
  .fdz-theme .md-button.md-fab.fdz-previous-search-result, .fdz-theme .md-button.md-fab.fdz-next-search-result {
    top: 50%;
  }
}
.fdz-theme .md-button.md-fab.fdz-previous-search-result {
  margin-left: -20px;
  transition: margin-left 0.25s;
  padding-right: 1px;
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}
.fdz-theme .md-button.md-fab.fdz-previous-search-result > md-icon {
  margin-left: 8px;
}
.fdz-theme .md-button.md-fab.fdz-previous-search-result:focus, .fdz-theme .md-button.md-fab.fdz-previous-search-result:hover {
  margin-left: 0px;
}
.fdz-theme .md-button.md-fab.fdz-next-search-result {
  right: -20px;
  margin-right: 0;
  padding-left: 1px;
  transition: right 0.25s;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}
.fdz-theme .md-button.md-fab.fdz-next-search-result > md-icon {
  margin-right: 8px;
}
.fdz-theme .md-button.md-fab.fdz-next-search-result:focus, .fdz-theme .md-button.md-fab.fdz-next-search-result:hover {
  right: 0;
}

fdz-breadcrumbs {
  display: flex;
  justify-content: center;
  flex: 0 0 auto;
}
fdz-breadcrumbs > * {
  max-width: 1000px;
}

.fdz-breadcrumbs {
  display: flex;
  margin: 8px 8px 8px 8px;
  width: 100%;
}
@media (min-width: 600px) {
  .fdz-breadcrumbs {
    margin-left: 16px;
    margin: 16px 8px 16px 8px;
  }
}
@media (min-width: 1400px) {
  .fdz-breadcrumbs {
    margin-left: 16px;
    margin: 16px 8px 16px 16px;
  }
}

.fdz-theme .fdz-search {
  position: relative;
  border: 1px rgba(0, 0, 0, 0.12) solid;
  border-radius: 2px;
  margin: 24px 8px 0px 8px;
  padding: 4px;
}
@media (min-width: 600px) {
  .fdz-theme .fdz-search {
    margin: 24px 16px 0px 16px;
  }
}
@media (min-width: 959px) {
  .fdz-theme .fdz-search {
    margin: 24px 24px 0 24px;
  }
}
.fdz-theme .fdz-search md-input-container.fdz-search__input {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}
.fdz-theme .fdz-search md-input-container.fdz-search__input md-icon {
  display: flex;
  position: relative;
  margin: 0 6px 0 6px;
  top: 0px;
  left: 0px;
  right: unset;
}
.fdz-theme .fdz-search md-input-container.fdz-search__input .md-input {
  flex-grow: 2;
  margin: 0;
  padding: 0;
  border: 0;
  order: unset;
  left: unset;
  width: inherit;
  line-height: 16px;
  height: 24px;
  -ms-flex-order: 0;
}
.fdz-theme .fdz-search md-input-container.fdz-search__input md-input-focused .md-input {
  border: 0;
  outline: none;
}
.fdz-theme .fdz-search md-input-container.fdz-search__input .md-button {
  flex-grow: 1;
  margin: 0;
  min-height: 24px;
  min-width: auto;
  max-width: 80px;
  border-radius: 0;
}
.fdz-theme .fdz-search md-progress-linear {
  display: block;
  position: absolute;
  width: 100%;
  height: 5px;
  padding-top: 0 !important;
  margin-bottom: 0 !important;
  left: 0;
  top: 27px;
}

md-tab-item:hover {
  background-color: rgba(158, 158, 158, 0.2) !important;
}

.md-select-placeholder {
  font-weight: bold;
}

.md-select-menu-ie11-fix {
  max-height: 15em;
}

.fdz-select-header {
  background-color: white;
  padding-left: 10px;
}

.fdz-select-header md-input-container {
  margin-bottom: 10px;
  margin-top: 10px;
}

.fdz-select-header md-input-container.md-input-focused label,
.fdz-select-header md-input-container.md-input-has-value label {
  bottom: 65% !important;
}

.fdz-select-header .md-errors-spacer {
  display: none;
}

.fdz-filter-select {
  margin-left: 8px;
  margin-right: 0;
  margin-bottom: 24px;
}

md-input-container.fdz-filter-select.md-input-focused label,
md-input-container.fdz-filter-select.md-input-has-value label {
  bottom: 89% !important;
}

input::-ms-clear {
  display: none;
}

.fdz-search-input {
  display: block;
  margin-left: 8px;
  margin-right: 16px;
  margin-bottom: 24px;
}

.fdz-search-input .md-errors-spacer {
  display: none;
}

md-input-container label {
  margin: 0px;
}

.fdz-filter-badge {
  display: inline;
  background-color: rgb(247, 168, 57);
  padding: 0.2em 0.6em;
  line-height: 1;
  color: black;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 1em;
}

.fdz-theme .fdz-detail {
  position: relative;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
@media (min-width: 600px) {
  .fdz-theme .fdz-detail {
    margin: 0 8px;
  }
}
.fdz-theme .fdz-detail__header {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 8px;
}
@media (min-width: 600px) {
  .fdz-theme .fdz-detail__header {
    padding: 16px;
  }
}
.fdz-theme .fdz-detail__header-text {
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  word-break: break-word;
}
.fdz-theme .fdz-embargo-warning {
  background-color: #F69F24;
  margin: 0px;
}
.fdz-theme .fdz-tag-link-container {
  display: inline;
  align-items: baseline;
  width: 100%;
}
.fdz-theme .fdz-tag-link-container h5 {
  color: rgba(0, 0, 0, 0.38);
  margin-right: 8px;
  display: inline-flex;
}
.fdz-theme .fdz-tag-link-container a {
  font-weight: normal;
  min-height: auto;
  min-width: auto;
  font-size: 100%;
}
.fdz-theme .fdz-detail__content {
  padding: 16px 0;
}
.fdz-theme .fdz-detail md-card {
  box-shadow: none;
  margin: 0;
}
.fdz-theme .fdz-detail md-card md-list {
  padding: 0;
}
.fdz-theme .fdz-detail md-card md-list-item:last-child {
  margin-bottom: 0;
}
.fdz-theme .fdz-detail md-card md-list-item,
.fdz-theme .fdz-detail md-card md-list-item::before {
  min-height: auto;
}
.fdz-theme .fdz-detail md-card md-list-item {
  padding: 0;
  margin-bottom: 8px;
  display: flex;
  flex-flow: wrap;
  align-items: baseline;
}
.fdz-theme .fdz-detail md-card md-list-item.fdz-code {
  align-items: center;
}
.fdz-theme .fdz-detail md-card md-list-item h5 {
  display: flex;
  width: 100%;
  line-height: unset;
}
.fdz-theme .fdz-detail md-card md-list-item .md-secondary-container {
  display: flex;
  margin: 0;
  width: 100%;
  flex-shrink: unset;
  flex-flow: wrap;
}
.fdz-theme .fdz-detail md-card md-list-item .md-secondary-container p:last-child {
  margin: 0px;
}
@media (min-width: 700px) {
  .fdz-theme .fdz-detail md-card md-list-item h5 {
    width: 30%;
  }
  .fdz-theme .fdz-detail md-card md-list-item .md-secondary-container {
    width: 50%;
  }
}
@media (min-width: 920px) {
  .fdz-theme .fdz-detail md-card md-list-item .md-secondary-container {
    max-width: 100%;
  }
}
.fdz-theme #related-objects .fdz-search {
  margin: 16px 0 8px 0;
}
.fdz-theme #related-objects fdz-paginator {
  margin: 0;
}
@media (min-width: 600px) {
  .fdz-theme #related-objects fdz-paginator {
    padding-left: 0;
  }
}
.fdz-theme #related-objects .fdz-detail-search-item-container md-card {
  margin: 0;
  padding: 8px 0;
}
.fdz-theme #related-objects .fdz-detail-search-item-container md-card-content {
  padding-top: 0;
}
@media (min-width: 600px) {
  .fdz-theme #related-objects .fdz-detail-search-item-container md-card-content {
    padding-top: 8px;
  }
}
.fdz-theme .fdz-theme .fdz-detail .fdz-attachment {
  padding: 8px 0;
}
.fdz-theme .fdz-theme .fdz-detail .fdz-attachment .fdz-table-wide,
.fdz-theme .fdz-theme .fdz-detail .fdz-attachment .fdz-table-rotated {
  margin: 0;
}
.fdz-theme .fdz-detail md-card md-card-content > p {
  margin-top: 0;
  margin-bottom: 0;
}
@media (min-width: 700px) {
  .fdz-theme .fdz-detail md-card md-card-content > p {
    columns: 2;
    text-align: justify;
  }
}
.fdz-theme survey-search-result md-card-content > div > div,
.fdz-theme dataset-search-result md-card-content > div > div {
  margin-right: unset;
}
@media (min-width: 960px) {
  .fdz-theme survey-search-result md-card-content > div > div,
  .fdz-theme dataset-search-result md-card-content > div > div {
    margin-right: 6px;
  }
}
.fdz-theme survey-search-result md-card-content > div > div ~ div,
.fdz-theme dataset-search-result md-card-content > div > div ~ div {
  margin-left: unset;
}
@media (min-width: 960px) {
  .fdz-theme survey-search-result md-card-content > div > div ~ div,
  .fdz-theme dataset-search-result md-card-content > div > div ~ div {
    margin-left: 6px;
  }
}
.fdz-theme .fdz-overlay-outer-box {
  padding: 8px;
}
.fdz-theme .questions .fdz-search-result {
  margin: 0;
}
.fdz-theme .fdz-questions > .questions:first-child .fdz-search-result {
  padding-top: 0px;
}
.fdz-theme .questions .fdz-search-result md-card-header, .fdz-theme .questions .fdz-search-result md-card-content {
  padding-right: 0;
  padding-left: 0;
}
.fdz-theme .fdz-questions > .questions .fdz-search-result {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
}
.fdz-theme .fdz-questions > .questions:last-child .fdz-search-result {
  border: none;
}
.fdz-theme #diagramContainer {
  margin-top: 16px;
}
.fdz-theme .fdz-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.fdz-theme .fdz-button-wrapper .image {
  position: absolute;
  right: 16px;
  bottom: 16px;
}
.fdz-theme .fdz-hidden-code,
.fdz-theme .fdz-hidden-code > pre {
  height: 52px;
  overflow: hidden;
}

.fdz-table-wide {
  width: fit-content;
  min-width: 100%;
  table-layout: auto;
  border-collapse: unset;
  text-align: left;
}

.fdz-table-wide thead {
  background-color: white;
  color: black;
}

.fdz-table-wide thead th {
  font-weight: bold;
}

.fdz-table-number {
  text-align: right;
  white-space: nowrap;
}

.fdz-table-date {
  text-align: left;
  white-space: nowrap;
}

.fdz-table-total-number {
  text-align: right;
  font-weight: bold;
  white-space: nowrap;
}

.fdz-table-wide th {
  border-top: none;
}

.fdz-table-wide td {
  border-top: 1px solid #ddd;
}

.fdz-table-wide td,
.fdz-table-wide th {
  padding: 8px 8px;
}

.fdz-table-wide td {
  vertical-align: top;
}

.fdz-table-wide td.fdz-td-filename {
  display: inline-block;
  width: 200px;
  word-break: break-all;
}

td.fdz-table-data-vertical-middle {
  vertical-align: middle;
}

.fdz-table-wide th {
  white-space: nowrap;
}

.fdz-selected-table-row,
.fdz-table-wide tbody > tr:hover {
  background-color: rgba(158, 158, 158, 0.2);
}

tr:focus {
  outline: #F69F24 auto 5px;
}

.fdz-table-absorbing-column {
  width: 100%;
}

.fdz-table-wide .fdz-table-half-absorbing-column {
  width: 50%;
}

.fdz-table-wide .fdz-table-third-absorbing-column {
  width: 33%;
}

.fdz-table-nowrap {
  white-space: nowrap;
}

.fdz-table-rotated {
  display: block;
  position: relative;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  border: 0;
}

.fdz-table-rotated thead {
  margin-left: 0px;
  display: block;
  float: left;
  width: 150px;
}

.fdz-table-rotated tbody {
  display: block;
  width: auto;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
}

.fdz-table-rotated td,
.fdz-table-rotated th {
  margin: 0;
  vertical-align: top;
  text-align: left;
}

.fdz-table-rotated th {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  border: 0;
  margin: 5px;
}

.fdz-table-wide td .md-icon-button {
  padding: 0;
  height: auto;
}

.fdz-table-rotated td .md-icon-button {
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  margin-left: 0;
  min-height: 0;
}

.fdz-table-rotated th:last-child {
  border-bottom: 0;
}

.fdz-table-rotated td {
  display: block;
  text-align: left;
  border: 0;
  margin: 5px;
}

.fdz-table-rotated thead tr {
  display: block;
}

.fdz-table-rotated tbody tr {
  display: inline-block;
  vertical-align: top;
  border-collapse: collapse;
  border-right: 1px solid #ddd;
  border-bottom: 0;
}

.fdz-table-rotated tbody tr:first-child {
  border-left: 1px solid #ddd;
}

.fdz-theme .fdz-package-filter {
  box-shadow: none;
  padding: 0 8px;
}
.fdz-theme .fdz-package-filter md-card-content {
  padding: 16px 16px 8px 16px;
}
.fdz-theme .fdz-package-filter md-card-content label {
  padding-bottom: 8px;
  font-weight: 500;
}
.fdz-theme .fdz-package-filter md-card-content md-radio-group {
  color: #000;
}
.fdz-theme .fdz-package-filter md-card-content md-radio-group md-radio-button:last-child {
  margin-bottom: 0;
}

.fdz-theme .fdz-search-filter {
  box-shadow: none;
  padding-top: 8px;
}
.fdz-theme .fdz-search-filter md-card-header {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  margin: 0;
  padding: 8px 0;
}
.fdz-theme .fdz-search-filter md-card-content {
  padding: 0;
}
.fdz-theme .fdz-search-filter md-card-content .fdz-button-wrapper {
  display: flex;
  justify-content: flex-end;
}
.fdz-theme .fdz-search-filter md-card-content .fdz-filter-menu-list {
  padding: 8px 0;
}
.fdz-theme .fdz-search-filter md-card-content .fdz-filter-menu-list .md-button {
  margin-left: 0;
  margin-top: 0;
  width: 100%;
  max-width: none;
  text-align: left;
  text-transform: capitalize;
}
.fdz-theme .fdz-search-filter md-card-content .fdz-filter-list {
  padding: 0 8px;
}
.fdz-theme .fdz-search-filter md-card-content .fdz-filter-list li md-checkbox {
  margin-bottom: 8px;
  min-height: 25px;
}
.fdz-theme .fdz-search-filter md-card-content .fdz-filter-list li md-checkbox .md-container {
  position: unset;
  transform: unset;
  box-sizing: border-box;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0px;
}
.fdz-theme .fdz-search-filter md-card-content .fdz-filter-list li md-checkbox .md-label {
  max-width: 270px;
  white-space: normal;
  vertical-align: top;
  margin-left: 30px;
  margin-top: 0px;
}

.fdz-search-filter-component md-input-container {
  margin: 0;
}

.fdz-search-filter-component md-input-container input {
  margin-top: 8px;
}

.fdz-search-filter-component {
  margin-bottom: 0 !important;
}

.fdz-search-filter-component button {
  top: 10px !important;
}

.fdz-search-filter-component md-progress-linear {
  bottom: 22px !important;
}

.fdz-search-filter-component md-input-container.md-input-focused label,
.fdz-search-filter-component md-input-container.md-input-has-value label {
  bottom: 75% !important;
}

.fdz-search-filter-component md-input-container label:not(.md-container-ignore) {
  bottom: 88%;
}

.fdz-search-filter-component .ng-active div[ng-message] {
  opacity: 1;
  margin-top: 0;
}

.md-autocomplete-suggestions.fdz-search-filter-items li.md-autocomplete-suggestion {
  border-bottom: 1px solid #ccc;
  height: auto;
  padding: 6px 12px 5px 12px;
  white-space: normal;
}

.fdz-search-filter-items .item-title,
.fdz-search-filter-items .item-metadata {
  display: block;
  line-height: 1.5;
}

.md-autocomplete-suggestions-container.md-not-found .fdz-search-filter-items li {
  margin-left: 0px;
  margin-top: 0px;
  padding: 4px 12px 2px 12px;
}

.fdz-theme .fdz-data-package {
  box-shadow: none;
  padding-top: 16px;
}
.fdz-theme .fdz-data-package md-card-header {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: rgba(0, 0, 0, 0.12);
  margin: 0;
  padding: 8px 0;
}
.fdz-theme .fdz-data-package md-card-header .title {
  font-size: 16px;
}
@media (min-width: 600px) {
  .fdz-theme .fdz-data-package md-card-header .title {
    font-size: 18px;
  }
}
.fdz-theme .fdz-data-package md-card-footer {
  padding: 0;
}
.fdz-theme .fdz-data-package md-list {
  padding: 0;
}
.fdz-theme .fdz-data-package md-list-item, .fdz-theme .fdz-data-package md-list-item::before {
  min-height: auto;
}
.fdz-theme .fdz-data-package md-list-item {
  padding: 0;
  flex-flow: column;
  align-items: flex-start;
  margin-bottom: 8px;
}
.fdz-theme .fdz-data-package md-list-item md-select {
  margin: 0;
  width: 100%;
  display: block;
}
.fdz-theme .fdz-data-package md-list-item h5 {
  width: 100%;
}
.fdz-theme .fdz-data-package md-list-item .md-secondary-container {
  width: 100%;
  margin: 0;
  flex: 1 1 auto;
}
.fdz-theme .fdz-data-package md-list-item .md-secondary-container > span {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.fdz-theme .fdz-data-package md-list-item .md-secondary-container div {
  flex-direction: row;
  display: flex;
  flex: 1 1 auto;
}
.fdz-theme .fdz-data-package md-list-item .md-secondary-container div .md-button {
  margin: -4px 0px -6px 6px;
  height: 32px;
}
@media (min-width: 375px) {
  .fdz-theme .fdz-data-package md-list-item {
    flex-flow: row;
    align-items: center;
  }
  .fdz-theme .fdz-data-package md-list-item h5 {
    width: 40%;
  }
  .fdz-theme .fdz-data-package md-list-item .md-secondary-container {
    width: 190px;
    overflow: hidden;
    word-break: break-all;
  }
}
.fdz-theme .fdz-data-package md-list-item .md-button.md-raised {
  margin-left: 0;
  margin-top: 16px;
  text-transform: none;
  min-width: 50%;
}

.fdz-theme fdz-paginator {
  width: 100%;
  margin: -8px 8px;
}
@media (min-width: 600px) {
  .fdz-theme fdz-paginator {
    padding: 0 8px;
  }
}
@media (min-width: 959px) {
  .fdz-theme fdz-paginator {
    padding: 0 16px;
  }
}
.fdz-theme fdz-paginator .fdz-paginator__container {
  margin: 0;
  padding: 0;
}
.fdz-theme fdz-paginator .fdz-paginator__container .fdz-paginator__container:first-child {
  display: none;
}
@media (min-width: 400px) {
  .fdz-theme fdz-paginator .fdz-paginator__container .fdz-paginator__container:first-child {
    display: flex;
  }
}
.fdz-theme fdz-paginator .fdz-paginator__select {
  margin-bottom: 2px;
}

.fdz-theme md-radio-button .md-off {
  border-color: rgba(0, 0, 0, 0.54);
}
.fdz-theme md-radio-button .md-on {
  background-color: #08fff2;
}
.fdz-theme md-radio-button.md-checked .md-off {
  border-color: #08fff2;
}
.fdz-theme md-radio-button.md-checked .md-ink-ripple {
  color: #08fff2;
}
.fdz-theme md-radio-button .md-container .md-ripple {
  color: rgb(240, 129, 45);
}

.fdz-theme md-radio-group:not([disabled]) .md-primary .md-on, .fdz-theme md-radio-group:not([disabled]).md-primary .md-on,
.fdz-theme md-radio-button:not([disabled]) .md-primary .md-on,
.fdz-theme md-radio-button:not([disabled]).md-primary .md-on {
  background-color: rgb(0, 113, 188);
}
.fdz-theme md-radio-group:not([disabled]) .md-primary .md-checked .md-off, .fdz-theme md-radio-group:not([disabled]) .md-primary.md-checked .md-off, .fdz-theme md-radio-group:not([disabled]).md-primary .md-checked .md-off, .fdz-theme md-radio-group:not([disabled]).md-primary.md-checked .md-off,
.fdz-theme md-radio-button:not([disabled]) .md-primary .md-checked .md-off,
.fdz-theme md-radio-button:not([disabled]) .md-primary.md-checked .md-off,
.fdz-theme md-radio-button:not([disabled]).md-primary .md-checked .md-off,
.fdz-theme md-radio-button:not([disabled]).md-primary.md-checked .md-off {
  border-color: rgb(0, 113, 188);
}
.fdz-theme md-radio-group:not([disabled]) .md-primary .md-checked .md-ink-ripple, .fdz-theme md-radio-group:not([disabled]) .md-primary.md-checked .md-ink-ripple, .fdz-theme md-radio-group:not([disabled]).md-primary .md-checked .md-ink-ripple, .fdz-theme md-radio-group:not([disabled]).md-primary.md-checked .md-ink-ripple,
.fdz-theme md-radio-button:not([disabled]) .md-primary .md-checked .md-ink-ripple,
.fdz-theme md-radio-button:not([disabled]) .md-primary.md-checked .md-ink-ripple,
.fdz-theme md-radio-button:not([disabled]).md-primary .md-checked .md-ink-ripple,
.fdz-theme md-radio-button:not([disabled]).md-primary.md-checked .md-ink-ripple {
  color: rgb(0, 113, 188);
}
.fdz-theme md-radio-group:not([disabled]) .md-primary .md-container .md-ripple, .fdz-theme md-radio-group:not([disabled]).md-primary .md-container .md-ripple,
.fdz-theme md-radio-button:not([disabled]) .md-primary .md-container .md-ripple,
.fdz-theme md-radio-button:not([disabled]).md-primary .md-container .md-ripple {
  color: rgb(0, 149, 228);
}
.fdz-theme md-radio-group[disabled],
.fdz-theme md-radio-button[disabled] {
  color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-radio-group[disabled] .md-container .md-off,
.fdz-theme md-radio-button[disabled] .md-container .md-off {
  border-color: rgba(0, 0, 0, 0.38);
}
.fdz-theme md-radio-group[disabled] .md-container .md-on,
.fdz-theme md-radio-button[disabled] .md-container .md-on {
  border-color: rgba(0, 0, 0, 0.38);
}

.fdz-theme md-radio-group .md-checked .md-ink-ripple {
  color: #08fff2;
}
.fdz-theme md-radio-group.md-primary .md-checked:not([disabled]) .md-ink-ripple, .fdz-theme md-radio-group .md-checked:not([disabled]).md-primary .md-ink-ripple {
  color: rgb(0, 113, 188);
}

.fdz-theme md-radio-group.md-focused.ng-empty > md-radio-button:first-child .md-container:before {
  background-color: rgba(0, 0, 0, 0.26);
}

.fdz-theme md-radio-group {
  color: rgb(0, 113, 188);
}
.fdz-theme md-radio-group .md-focused:not(:empty) .md-checked .md-container:before {
  background-color: #08fff2;
}
.fdz-theme md-radio-group .md-focused:not(:empty).md-primary .md-checked .md-container:before,
.fdz-theme md-radio-group .md-focused:not(:empty) .md-checked.md-primary .md-container:before {
  background-color: rgb(0, 113, 188);
}
.fdz-theme md-radio-group .md-focused:not(:empty).md-warn .md-checked .md-container:before,
.fdz-theme md-radio-group .md-focused:not(:empty) .md-checked.md-warn .md-container:before {
  background-color: #fb0cff;
}

.fdz-theme md-radio-group#twitter-image-switch md-radio-button.md-checked .md-off {
  border-color: rgb(247, 168, 57);
}

.fdz-theme md-radio-group#twitter-image-switch md-radio-button.md-checked .md-on {
  background-color: rgb(247, 168, 57);
}

geographic-coverage-list {
  display: block;
}
geographic-coverage-list md-card-content {
  padding: 0;
}
geographic-coverage-list ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

geographic-coverage {
  display: block;
}

.fdz-overlay-outer-box {
  width: 100%;
  display: block;
  position: relative;
}

.fdz-overlay-outer-box .fdz-overlay-inner-box-top {
  top: 0;
  right: 0;
  position: absolute;
}

.fdz-overlay-outer-box .fdz-overlay-inner-box-bottom {
  bottom: 0;
  right: 0;
  position: absolute;
}

.fdz-fab-button-container {
  position: fixed;
  bottom: 1em;
  right: 1em;
  z-index: 15;
}

md-dialog .fdz-fab-button-container {
  position: absolute;
}

.fdz-theme .fdz-shopping-cart__header {
  margin: 0 0 16px 8px;
}
.fdz-theme .fdz-shopping-cart__header h1 {
  margin: 0;
}

.fdz-theme md-select.fdz-inline-select {
  margin: 0px;
  max-height: 24px;
  display: inline-flex;
}
.fdz-theme md-select.fdz-inline-select .md-select-placeholder {
  font-weight: unset;
  color: rgb(0, 113, 188);
}
.fdz-theme md-select.fdz-inline-select .md-select-icon {
  color: rgb(0, 113, 188);
  width: 15px;
  margin: 0;
}
.fdz-theme md-select.fdz-inline-select .md-select-value {
  padding: 0px;
}

.fdz-theme md-select.fdz-inline-select:focus {
  outline: #F69F24 auto 5px;
  color: rgb(0, 113, 188);
}

.fdz-speech-bubble {
  position: relative;
  max-width: 30em;
  background-color: rgb(0, 113, 188);
  padding: 0px;
  margin-bottom: 6px;
  margin-left: 12px;
  border-radius: 1rem;
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.3), 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.2);
}

.fdz-speech-bubble p {
  padding: 1.125em 1.5em;
  margin: 0px;
  color: rgb(255, 255, 255);
}

.fdz-speech-bubble a:hover, .fdz-speech-bubble a:focus {
  text-decoration: none;
}

.fdz-speech-bubble::after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: 100%;
  right: 25px;
  border: 10px solid transparent;
  border-top: none;
  transform: rotate(180deg);
  border-bottom-color: rgb(0, 113, 188);
  filter: drop-shadow(0 -0.0625rem 0.0625rem rgba(0, 0, 0, 0.1));
}

.fdz-theme user-consent-component {
  position: fixed;
  bottom: -200px;
  width: 100%;
  z-index: 5000;
  background-color: rgb(0, 113, 188);
  color: rgb(255, 255, 255);
  padding: 16px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  transition: bottom 1s linear;
  border-top-color: rgb(255, 255, 255);
  border-top-style: solid;
  border-top-width: 1px;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
}
.fdz-theme user-consent-component p {
  display: block;
  margin-bottom: 8px;
  text-align: justify;
}
.fdz-theme user-consent-component p a {
  color: rgb(255, 255, 255);
  text-decoration: underline;
}
.fdz-theme user-consent-component div {
  text-align: right;
}
.fdz-theme user-consent-component div .md-button {
  margin: 0px;
}

.fdz-no-js {
  position: absolute;
  background-color: #F69F24;
  color: black !important;
  animation: blinker 2s linear;
  margin: 6px;
  border-radius: 6px;
  padding: 6px;
  font-size: 26px;
}

.fdz-no-js p {
  margin: 0px;
}

.fdz-skip-navigation {
  position: absolute !important;
  left: 0;
  top: 0;
  opacity: 0;
  z-index: -100;
  display: block;
  width: 95%;
  margin: 0.5em;
  -webkit-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.fdz-skip-navigation:focus {
  opacity: 0.9;
  z-index: 60;
}

.fdz-fade-in.ng-enter {
  -webkit-transition: 1s;
  transition: 1s;
  opacity: 0;
}

.fdz-fade-in.ng-enter-active {
  opacity: 1;
}

.fdz-odd-row {
  background-color: #f9f9f9;
}

.fdz-theme .fdz-metadata-type-state-card {
  padding: 8px;
  display: block;
  min-width: 30%;
}
@media screen and (max-width: 1600px) {
  .fdz-theme .fdz-metadata-type-state-card {
    min-width: 45%;
  }
}
.fdz-theme .fdz-metadata-type-state-card md-card-header,
.fdz-theme .fdz-metadata-type-state-card md-card-header-text {
  justify-content: space-between;
}
.fdz-theme .fdz-metadata-type-state-card .md-button {
  min-width: 88px;
}
.fdz-theme .fdz-metadata-type-state-card md-checkbox {
  margin-bottom: 16px;
  margin-right: 16px;
}

.fdz-theme .fdz-metadata-type-state-card.ng-hide:not(.ng-hide-animate) {
  visibility: hidden;
  opacity: 0;
  display: inherit !important;
}

project-cockpit-status {
  display: block;
}
@media screen and (max-width: 960px) {
  project-cockpit-status {
    margin-bottom: 150px;
  }
}

project-status-badge,
release-status-badge {
  display: inline-block;
}

project-status-badge > span, release-status-badge > span {
  font-size: 1em !important;
}

.fdz-project-user-list + md-list md-list-item {
  background: white;
  animation: fade-highlight 1s ease-out;
}

.fdz-project-status-overview-metadata-status-table-cell {
  min-height: 28px;
}

.fdz-theme md-dialog > md-toolbar {
  padding: 0 8px;
}

.fdz-theme .md-dialog-content > h2 {
  margin-bottom: 16px;
}

.checkbox-label {
  color: rgba(0, 0, 0, 0.38);
}

.checkbox-component {
  padding-left: 8px;
  padding-bottom: 18px;
  margin: 0px;
}